import React from 'react'
// import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  InnerTextWrapper,
  InnerPhotoWrapper,
  Title,
  Name,
  LawyerText,
  InnerPhoto,
  InnerContent,
  Inner,
  ContactInfoWrapper,
} from './ContactPerson.styles'
import {Container} from '../LawyerContacts/LawyerContacts.styles'
import Text from '../Text'
import CommonContactsText from '../CommonContactsText'
import VideoConferenceIcons from '../VideoConferenceIcons'

const ContactPerson = ({
  children,
  name,
  bureau,
  position,
  photo,
  street,
  contactInfo,
  speak,
  ...rest
}) => {
  const {t} = useTranslation()

  return (
    <Container {...rest}>
      <Inner>
        <InnerPhotoWrapper>
          <InnerPhoto src={photo} />
          <InnerTextWrapper>
            <Title content={t('service.contactsTitle')} />
            <Name content={name} />
            <LawyerText content={position} />
          </InnerTextWrapper>
        </InnerPhotoWrapper>
        <InnerContent>
          <Text content={bureau} mb={22} uppercase fontWeight={700} />
          <Text content={t('service.ag')} mb={5} />
          <Text content={street} mb={5} />
          <ContactInfoWrapper>
            <MarkdownView
              markdown={contactInfo}
              options={{strikethrough: true, noHeaderId: true}}
            />
          </ContactInfoWrapper>
          <CommonContactsText />
          <VideoConferenceIcons />
        </InnerContent>
      </Inner>
    </Container>
  )
}

ContactPerson.propTypes = {}
ContactPerson.defaultProps = {}

export default ContactPerson
