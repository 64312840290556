import React, {useEffect, useRef} from 'react'
// import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import {Wrapper, InnerWrapper, MarkdownWrapper} from './ServiceContent.styles'
import Text from '../../components/Text'
import ContentContainer from '../../components/ContentContainer'

const ServiceContent = ({children, content, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <InnerWrapper>
        <MarkdownWrapper>
          <MarkdownView markdown={content} options={{strikethrough: true, noHeadersId: true}} />
        </MarkdownWrapper>
      </InnerWrapper>
    </ContentContainer>
  </Wrapper>
)

ServiceContent.propTypes = {}
ServiceContent.defaultProps = {}

export default ServiceContent
