import React from 'react'
// import PropTypes from 'prop-types'
import Text from 'components/Text'
import MarkdownView from 'react-showdown'
import {Wrapper, InnerWrapper} from './ServiceOurText.styles'
import ContentContainer from '../ContentContainer'

const ServiceOurText = ({title, subtitle, serviceText, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <InnerWrapper>
        <Text content={title} mb={10} size="titleXs" uppercase />
        <Text content={subtitle} mb={30} />
        <MarkdownView markdown={serviceText} options={{strikethrough: true, noHeaderId: true}}/>
      </InnerWrapper>
    </ContentContainer>
  </Wrapper>
)

ServiceOurText.propTypes = {}
ServiceOurText.defaultProps = {}

export default ServiceOurText
