import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  padding: '80px 0',
  background: theme.palette.primary.bg,

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 700,

  '& a': {
    color: 'blue',
  },

  '& ul': {
    paddingLeft: 15,
  },

  '& li': {
    marginBottom: 15,

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))
