import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {createGlobalStyle} from 'styled-components'
import BlogBlockSection from '../sections/BlogBlockSection'
import BlogNav from '../components/BlogNav'
import HeroWithSlider from '../components/HeroWithSlider'
import ReviewsSection from '../components/ReviewsSection'
import ServiceContent from '../sections/ServiceContent'
import ServiceOurText from '../components/ServiceOurText'
import ContactPerson from '../components/ContactPerson'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Layout from "../components/Layout";

const GeneralContactsHide = createGlobalStyle`
  .GeneralContacts {
    display: none;
  }
`

const ArticleTemplate = ({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  location,
}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiService.en_disabled) {
      changeLanguage('de')
    }
  }, [])


  const sortedBlogs = data.strapiService.blogs
    .map(i => {
      // eslint-disable-next-line no-param-reassign
      i.sort = new Date(i.date_for_sort).getTime()
      return i
    })
    .sort((a, b) => b.sort - a.sort)

  return (
    <Layout dataLocales={data} location={location}>
      <Header dataLang={data.strapiService.en_disabled} />
      <GeneralContactsHide whiteColor />
      <SEO
        title={data.strapiService.seo_title && data.strapiService.seo_title[language]}
        description={
          data.strapiService.seo_description && data.strapiService.seo_description[language]
        }
      />
      <HeroWithSlider
        dataSingleService={data.strapiService.lawyers_group}
        // dataSingleServicePosition={}
        title={data.strapiService.title[language]}
        serviceSmallTitle={t('sections.hero.serviceSmallTitle')}
        crumbs={crumbs}
        crumbLabel={data.strapiService.name[language]}
      />
      <BlogNav navData={data.strapiService.navigation[language]} />
      <ServiceContent content={data.strapiService.content[language]} id="serviceContact" />
      <ServiceOurText
        title={t('sections.serviceOurText.title')}
        subtitle={data.strapiService.service_subtitle[language]}
        serviceText={data.strapiService.service_text[language]}
        id="ourServices"
      />
      <ContactPerson
        name={data.strapiService.contact_lawyer?.name}
        position={data.strapiService.contact_lawyer?.position[language]}
        street={data.strapiService.contact_lawyer?.street}
        contactInfo={data.strapiService.contact_lawyer?.contact_info[language]}
        speak={data.strapiService.contact_lawyer?.speak[language]}
        bureau={data.strapiService.contact_lawyer?.bureau[language]}
        photo={data.strapiService.contact_lawyer?.small_photo.url}
      />
      <ReviewsSection />
      <BlogBlockSection
        dataSingle={sortedBlogs}
        title={`${t('sections.blog.titleSingleService')} ${data.strapiService.name[language]}`}
        subtitle={`${t('sections.blog.subtitleSingleService')} ${
          data.strapiService.name[language]
        }`}
        location={location}
      />
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ServiceTemplate($page_url: String!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    strapiService(page_url: {eq: $page_url}) {
      en_disabled
      seo_title {
        de
        en
      }
      title {
        de
        en
      }
      seo_description {
        de
        en
      }
      name {
        de
        en
      }
      service_subtitle {
        de
        en
      }
      service_text {
        de
        en
      }
      content {
        de
        en
      }
      contact_lawyer {
        name
        position {
          de
          en
        }
        bureau {
          de
          en
        }
        small_photo {
          url
        }
        speak {
          de
          en
        }
        street
        contact_info {
          en
          de
        }
      }
      lawyers_group {
        lawyers {
          name
          small_photo {
            url
          }
          street
          photo {
            url
          }
        }
      }
      lawyers {
        name
        position {
          de
          en
        }
        speak {
          de
          en
        }
        small_photo {
          url
        }
        street
        contact_info {
          de
          en
        }
        photo {
          url
        }
      }
      page_url
      navigation {
        en
        de
      }
      blogs {
        date_for_sort
        page_url
        title {
          de
          en
        }
        description_short {
          de
          en
        }
        date {
          de
          en
        }
      }
    }
  }
`
