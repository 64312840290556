import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  paddingBottom: 60,
}))

export const MarkdownWrapper = styled.div(({theme}) => ({}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 700,

  '& a': {
    color: 'blue',
  },

  '& h1, h2, h3, h4, h5, h6': {
    marginBottom: 20,
    fontWeight: 700,
    color: theme.palette.grey[1000],
    lineHeight: 1.2,
  },

  '& h1': {
    fontSize: theme.fontSizes.titleLg,
  },

  '& h2': {
    fontSize: theme.fontSizes.title,
  },

  '& h3': {
    fontSize: theme.fontSizes.titleXs,
  },

  '& h4': {
    fontSize: theme.fontSizes.md,
  },

  '& p': {
    marginBottom: 40,
  },

  '& ol, ul': {
    padding: 0,
    marginLeft: 15,
  },

  '& ul': {
    padding: 0,
    marginLeft: 0,
    listStyle: 'none',

    '& li': {
      '::before': {
        content: "'•'",
        display: 'inline-block',
        marginRight: 10,
      },
    },
  },
}))
