import styled from 'styled-components'
import Text from 'components/Text'

export const InnerPhotoWrapper = styled.div(({theme}) => ({
  maxWidth: 370,
  height: 0,
  position: 'relative',
  paddingBottom: '43%',
  width: '100%',
  marginLeft: 'auto',
  pointerEvents: 'all',
  [theme.breakpoints.down('laptopS')]: {
    maxWidth: 375,
  },
}))

export const InnerPhoto = styled.img(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: -1,
  height: '100%',
  border: `1px solid ${theme.palette.common.black}`,
}))

export const Inner = styled.div(({theme}) => ({
  marginBottom: 30,
  marginLeft: 'auto',
  maxWidth: 370,
  border: `1px solid ${theme.palette.common.black}`,
  pointerEvents: 'all',

  [theme.breakpoints.down('laptopS')]: {
    maxWidth: 375,
    marginBottom: 0,
    marginRight: '-14px',
    marginLeft: '-14px',
  },
}))

export const InnerContent = styled.div(({theme}) => ({
  padding: '11px 15px 25px',
  background: theme.palette.common.white,
}))

export const ContactInfoWrapper = styled.div(({theme}) => ({
  marginBottom: 15,

  '& > div > p': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  },

  '& p': {
    marginBottom: 0,
  },
}))

export const Title = styled(Text)(({theme}) => ({}))

export const InnerTextWrapper = styled.div(({theme}) => ({
  color: theme.palette.common.white,
  paddingLeft: 15,
  paddingTop: 40,
}))

export const Name = styled(Text)(({theme}) => ({
  fontWeight: 700,
  textTransform: 'uppercase',
}))
export const LawyerText = styled(Text)(({theme}) => ({}))
